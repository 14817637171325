import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import x2js from 'x2js'

import * as echarts from 'echarts/core'
import { GaugeChart } from 'echarts/charts'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

echarts.use([
  GaugeChart,
  CanvasRenderer,
  SVGRenderer
])

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts
// eslint-disable-next-line new-cap
Vue.prototype.$x2js = new x2js()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
