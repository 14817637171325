import Vue from 'vue'
import Vuex from 'vuex'
import WebSocketClient from '@/common/util/websocket.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: { id: '11111111' },
    ws: null
  },
  mutations: {
    initWebSocket (state, config) {
      state.ws = new WebSocketClient(config)
      state.ws.init()
    }
  },
  actions: {
  },
  modules: {
  }
})
