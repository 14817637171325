import store from '@/store'

class WebSocketClient {
  constructor (config = {}) {
    const defaultConfig = {
      url: '127.0.0.1',
      port: '8889',
      protocol: 'ws'
    }
    const finalConfig = { ...defaultConfig, ...config }
    this.ws = {}
    this.port = finalConfig.port
    this.url = finalConfig.url
    this.protocol = finalConfig.protocol
    this.interval = null
    this.intervalTime = 30 * 1000
  }

  init () {
    this.ws = new WebSocket(`${this.protocol}://${this.url}:${this.port}/ws`)
    this.ws.onopen = this.onopen.bind(this)
    this.ws.onmessage = this.onmessage
    this.ws.onclose = this.onclose.bind(this)
    this.ws.onerror = this.onerror
  }

  keepalive () {
    clearInterval(this.interval)
    this.interval = setInterval(() => {
      if (this.ws.readyState === 1) {
        const data = {}
        data.actionType = 4
        this.send(JSON.stringify(data))
      }
    }, this.intervalTime)
  }

  reconnect () {
    setTimeout(() => {
      this.init()
    }, 5000)
  }

  send (msg) {
    this.ws.send(msg)
  }

  onopen () {
    console.log('open')
    const initData = {}
    initData.actionType = 1
    initData.msgVo = {}
    initData.msgVo.senderId = store.state.userInfo.id
    this.send(JSON.stringify(initData))
    this.keepalive()
  }

  onerror () {
    console.log('error')
  }

  onclose () {
    console.log('close')
    this.reconnect()
  }

  onmessage (event) {
    console.log('message: ' + event.data)
  }
}

export default WebSocketClient
